import styled from 'styled-components';
import check from '../../assets/images/check.png';

export const Container = styled.article`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TitleOffer = styled.h2`
  width: 100%;
  line-height: 30px;
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: all 1.5s;
  transform: translateY(3em);
`;

export const List = styled.ul`
  padding: 20px;
  list-style: none;
  .item-reveal {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all 1.5s;
    transform: translateY(2.5em);
  }
`;

export const Item = styled.li`
  padding: 2px;
  margin-left: 10px;
  &:before {
    content: '';
    display: block;
    background: url(${check}) no-repeat;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 6px 0 0;
  }
`;

export const Button = styled.button`
  font-size: 1.5rem;
  padding: 15px 45px;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: inline-block;
  background: var(--primary-color);
  color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  position: relative;
  z-index: 1;
  transform: translateY(50px);
  opacity: 0;
  &:focus {
    color: var(--primary-color);
    background: var(--secondary-color);
  }
  &:active {
    color: var(--primary-color);
    background: var(--secondary-color);
  }
  &:hover {
    color: var(--primary-color);
    background: var(--secondary-color);
    transition: all 0.2s ease-in-out;
  }
`;

export const ImageContainer = styled.div`
  padding: 10px;
  align-items: center;
  justify-content: center;
`;
