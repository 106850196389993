import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${media.greaterThan('large')`
    max-width:1170px;
  `}
`;

export const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  width: 100%;
  ${media.greaterThan('large')`
    grid-template-columns: 1fr 1fr;
  `}
`;
