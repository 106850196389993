import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import * as S from './styled';

import TitlePage from '../../components/TitlePage';
import WrapperPage from '../../components/WrapperPage';
import WrapperSection from '../../components/WrapperSection';
import ServicesBoxItem from '../../components/ServicesBoxItem';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import PageImage from '../../components/PageImage';

const ITServices = ({ data }) => {
  const {
    items: offer,
    title,
    subtitle,
  } = data.file.childMdx.frontmatter;
  return (
    <Layout>
      <WrapperPage id="offer">
        <PageImage>
          <StaticImage
            src="../../assets/images/support.jpg"
            alt="Oferta"
            style={{ width: '100%', height: '100%', opacity: 0.2 }}
            layout="fullWidth"
            objectPosition="center"
            quality={85}
            imgStyle={{ filter: 'grayscale()' }}
          />
        </PageImage>
        <WrapperSection>
          <S.Container>
            <TitlePage text={title} subtitle={subtitle} />
            <S.ItemsWrapper>
              {offer.map((item) => (
                <ServicesBoxItem
                  key={item.title}
                  title={item.title}
                  tasks={item.tasks}
                  image={item.image}
                />
              ))}
            </S.ItemsWrapper>
          </S.Container>
        </WrapperSection>
      </WrapperPage>
    </Layout>
  );
};

export default ITServices;

export const Head = ({ data }) => (
  <SEO
    title={`${data.file.childMdx.frontmatter.title} - Giedeé Informatyka`}
  />
);

export const query = graphql`
  query MyOfferQuery($locale: String!) {
    file(
      extension: { eq: "mdx" }
      relativeDirectory: { eq: "it-services" }
      childMdx: { fields: { locale: { eq: $locale } } }
    ) {
      childMdx {
        frontmatter {
          subtitle
          title
          language
          items {
            title
            tasks
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  height: 60
                  quality: 85
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
