import React, { useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Expo, gsap } from 'gsap';

import * as S from './styled';

const ServicesBoxItem = ({ title, tasks, image }) => {
  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.5, ease: Expo.easeOut },
    });
    gsap.set('.ikon-service', { alpha: 0, y: -150 });
    tl.to('.ikon-service', {
      y: 0,
      alpha: 1,
      duration: 2,
      delay: 2,
    })
      .to(
        '.title-offer',
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          y: 0,
          stagger: 0.2,
          duration: 1,
        },
        '-=2'
      )
      .to(
        '.item-reveal',
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          y: 0,
          stagger: 0.1,
          duration: 0.2,
        },
        '-=1.2'
      )
      .play();
  }, []);

  const img = getImage(image);

  return (
    <S.Container>
      <S.TopContainer>
        <S.ImageContainer className="ikon-service">
          <GatsbyImage image={img} alt={title} object-fit="contain" />
        </S.ImageContainer>
        <S.TitleOffer className="title-offer">{title}</S.TitleOffer>
      </S.TopContainer>
      <S.List>
        {tasks.map((task) => (
          <S.Item className="item-reveal" key={task}>
            {task}
          </S.Item>
        ))}
      </S.List>
    </S.Container>
  );
};

export default ServicesBoxItem;
